<template>
  <div class="speechcraft">
    <div class="header">
      <!-- Tabs -->
      <van-tabs v-model:active="activeTab" type="card" color="#1989fa">
        <van-tab
          v-for="(type, idx) in types"
          :key="type.id"
          :name="idx"
          :title="type.cname"
        />
      </van-tabs>
      <!-- 管理 -->
      <van-button
        v-if="activeType.setting"
        type="primary"
        :plain="!activeType.openSetting"
        round
        icon="setting-o"
        size="small"
        @click="activeType.openSetting = !activeType.openSetting"
      ></van-button>
      <!-- 搜索 -->
      <van-button
        type="primary"
        plain
        round
        icon="search"
        size="small"
        @click="handleSearch"
      ></van-button>
    </div>
    <template v-if="activeTab === 0">
      <van-collapse v-model="activeNames" accordion>
        <van-collapse-item
          :title="item.content"
          :name="index"
          v-for="(item, index) of aitypes"
          :key="index"
          class="aitypes-items"
        >
          <template #title>
            <div class="summary">
              <div class="date-time">
                <span class="time">{{ item.msg_time }}</span>
                <span @click.stop="showDetail(item)" class="detail">详情</span>
              </div>
              <div class="summary-txt">{{ item.content }}</div>
              <div class="total-keyword">
                关键词: {{ item.total_keyword_name }}
              </div>
            </div>
          </template>
          <div class="aitype-box"></div>
          <div class="speech-list">
            <div class="speech-item" v-for="(i, j) of item.smart_taik" :key="j">
              <div class="speech-content">{{ i.speech_technique }}</div>
              <div class="buttons">
                <van-button type="primary" @click="sendMessage(i)">
                  发送
                </van-button>
                <van-button type="primary" @click="replayMessage(i, item)">
                  回复
                </van-button>
              </div>
            </div>
          </div>
        </van-collapse-item>
        <!-- <van-collapse-item title="标题2" name="2">内容</van-collapse-item>
        <van-collapse-item title="标题3" name="3">内容</van-collapse-item> -->
      </van-collapse>
      <div class="no-data" v-show="aitypes.length == 0">{{ noDataMsg }}</div>
    </template>
    <!-- 非智能话术 -->
    <template v-else>
      <!-- 话术分组 -->
      <template v-for="(type, idx) in types" :key="type.id">
        <div
          v-show="activeTab === idx"
          :class="type.openSetting ? 'setting' : ''"
        >
          <!-- 收缩面板 -->
          <van-collapse v-model="type.active" accordion>
            <van-collapse-item
              v-for="group in type.groups"
              :key="group.id"
              :name="group.id"
              :title="group.cname"
              style="min-height: 40px"
              ref="collapse"
            >
              <list
                :group-id="groupId"
                :customer-id="customerId"
                :params="group._params"
                :setting="type.openSetting"
                @click-item="handleModify"
                :ref="(el) => (group.instance = el)"
              />
            </van-collapse-item>
          </van-collapse>
        </div>
      </template>
    </template>

    <!-- 底部新增按钮 -->
    <div v-if="activeType.openSetting" class="footer">
      <van-button type="primary" round block @click="handleAdd">
        新增
      </van-button>
    </div>
  </div>
  <!-- 新增话术 & 修改话术 -->
  <van-dialog
    v-model:show="visibleEdit"
    :title="editData ? '修改话术' : '新增话术'"
    show-cancel-button
    :before-close="editCallback"
  >
    <van-field
      v-model="content"
      class="dialog-field"
      type="textarea"
      placeholder="请输入话术内容"
    />
  </van-dialog>
</template>

<script>
import { sendChatMessage } from '../../../utils/nwechat'
import {
  Tabs,
  Tab,
  Collapse,
  CollapseItem,
  Icon,
  Search,
  Button,
  Field,
  Dialog,
  Notify,
  Toast
} from 'vant'
import {
  getAiSpeechcraftType,
  getSpeechcraftType,
  getSpeechcraftGroup,
  addSpeechcraft,
  editSpeechcraft
} from '../../../service/tool.service'
import {
  getContext,
  getCurExternalChat,
  getCurExternalContact
} from '../../../utils/nwechat'
import List from './_list.vue'

export default {
  name: 'Speechcraft',
  components: {
    List,
    [Icon.name]: Icon,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Search.name]: Search,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      noDataMsg: '聊天内容中暂无关键词匹配的智能话术',
      // 话术类型
      types: [],
      activeNames: [0],
      // 智能话术类型
      aitypes: [], // 智能话术类型
      // 激活的tab idx
      activeTab: null,
      // 查询条件
      query: '',
      // 编辑弹窗
      visibleEdit: false,
      // 内容
      content: '',
      // 当前需要修改的数据源，如果是新增则为 null
      editData: null,
      // 群聊ID
      groupId: null,
      // 客户ID
      customerId: null
    }
  },
  computed: {
    activeType() {
      return this.types[this.activeTab] ?? {}
    }
  },
  async created() {
    this.$nextTick(async function () {
      this.initTypes()
      // 获取聊天类型(单聊/群聊)
      await this.initChat()
    })
  },
  methods: {
    sendMessage(params1) {
      // 话术内容
      let {
        speech_technique: content,
        content_id: id,
        followup_type_id: classId
      } = params1
      let options = { content, id, classId }
      console.log('点击了发送===>', options)
      this.send(options)
    },
    replayMessage(params1 = {}, params2 = {}) {
      let {
        speech_technique: content,
        content_id: id,
        followup_type_id: classId
      } = params1
      // content1  问题详情, name 客户名称
      let { content: content1, name } = params2
      let name1 = name
      name = this.groupId ? `@${name}` : ''
      content = ` | ${name1}:
 | ${content1}
------------------------------
${name}
${content}`
      let options = { content, id, classId }
      console.log('点击了回复====>', options, content)
      this.send(options)
    },
    async send(options) {
      console.log('options', options)
      try {
        await sendChatMessage(options.content)
        addFlollow({
          external_userid: this.customerId,
          chat_id: this.groupId,
          content_id: options.id,
          followup_type_id: options.classId
        })
      } catch (e) {}
    },
    showDetail(item) {
      let { content, msg_time, total_keyword_name } = item
      let message = `${msg_time}
关键词：${total_keyword_name}
${content}`
      Dialog.alert({
        title: '会话详情',
        message: message,
        messageAlign: 'left',
        className: 'theme-detail'
      }).then(() => {
        // on close
      })
      console.log('点击了详情')
    },
    // 初始化聊天类型
    async initChat() {
      try {
        const entry = await getContext()
        if (entry === 'group_chat_tools') {
          // 群聊
          this.groupId = await getCurExternalChat()
        } else if (entry === 'single_chat_tools') {
          // 单聊
          this.customerId = await getCurExternalContact()
        }
        this.getAiList()
        console.log('groupId: ', this.groupId)
        console.log('customerId:', this.customerId)
      } catch (e) {
        console.log('initChat', e)
      }
    },
    // 初始化话术类型 -> 公司 | 个人
    async initTypes() {
      Toast.loading({ message: '加载中...', forbidClick: true })
      try {
        const types = await getSpeechcraftType()
        types.forEach((item) =>
          Object.assign(item, {
            active: null,
            groups: [],
            openSetting: false
          })
        )
        this.types = types
        this.activeTab = 0
      } catch (error) {
        console.log('获取话术类型失败！', error)
        // Notify({ type: 'danger', message: '获取话术类型失败！' })
      }
      Toast.clear()
    },
    // 初始化话术分组数据
    async initGroup() {
      Toast.loading({ message: '加载中...', forbidClick: true })
      try {
        const type = this.types[this.activeTab]
        const groups = await getSpeechcraftGroup({ typeId: type.id })
        groups.forEach((item) => {
          Object.assign(item, {
            _params: { typeId: type.id, groupId: item.id }
          })
        })
        type.groups = groups
        type.active = groups[0]?.id
      } catch (e) {
        Notify({ type: 'danger', message: '话术分组列表数据获取失败！' })
      }
      Toast.clear()
    },
    // 跳转查询页面
    handleSearch() {
      this.$router.push({ name: 'speechcraft-search' })
    },
    // 新增
    handleAdd() {
      if (!this.activeType.openSetting) return
      if (!this.activeType.active) {
        Toast.fail('请先激活所需添加的分组')
        return
      }
      this.visibleEdit = true
      this.editData = null
      this.content = ''
    },
    // 修改
    handleModify(item) {
      if (!this.activeType.openSetting) return

      this.visibleEdit = true
      this.editData = item
      this.content = item.content
    },
    // 新增或修改回调
    async editCallback(action) {
      // 取消
      if (action !== 'confirm') return true
      // 内容校验
      if (!this.content) {
        Toast('你需要输入点内容！')
        return false
      }
      // 确定
      try {
        if (this.editData) {
          await this._modify()
        } else {
          await this._add()
        }
      } catch (e) {
        Notify({ type: 'danger', message: '提交失败！' })
        return false
      }
      return true
    },
    // 修改逻辑
    async _modify() {
      await editSpeechcraft({
        id: this.editData.id,
        title: this.editData.title,
        content: this.content
      })
      this.editData.content = this.content
    },
    async getAiList() {
      let { groupId = '', customerId } = this
      if (!groupId && !customerId) {
        console.log('获取智能话术===>')
        return
      }
      // groupId = 'wr1DICCQAAwUBksBk9YprrSc-PvFCyTA'
      let params = { chat_id: groupId || '', customer_id: customerId || '' }
      const types = await getAiSpeechcraftType(params)
        .then((res) => {
          console.warn('getAiSpeechcraftType11', Array.isArray(res))
          console.warn('getAiSpeechcraftType33', res)
          this.aitypes = res || []
        })
        .catch((error) => {
          console.error('getAiSpeechcraftType222', error)
          this.noDataMsg = error.msg || this.noDataMsg

          // this.aitypes = [
          //   {
          //     msg_time: '2020-01-03',
          //     content:
          //       '这是一段恢复这是一段恢复这是一段恢复这是一段恢复这是一段恢复这是一段恢复'
          //   },
          //   {
          //     msg_time: '2020-01-04',
          //     content: '这是一段恢复这是一段恢复这是一段'
          //   }
          // ]
        })
      console.error('获取智能话术列表===========》', types)
    },
    // 新增逻辑
    async _add() {
      const type = this.types[this.activeTab]

      const data = await addSpeechcraft({
        typeId: type.id,
        // typeName: type.name,
        groupId: type.active,
        content: this.content
      })

      const group = type.groups.find(({ id }) => id === type.active)
      group.instance.list.unshift(data)
    }
  },
  watch: {
    activeType(val, oldVal) {
      // 选顶部Tab，来初始化分组数据
      console.log('activeType发生变化', this.activeTab)
      if (this.activeType.groups?.length === 0) {
        let activeTab = this.activeTab
        if (activeTab == 0) {
          // 获取智能话术;
          this.getAiList()
        } else {
          this.initGroup()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/style/theme.less';

.speechcraft {
  > .header {
    display: flex;
    > .van-tabs {
      flex: 1;
    }
    > .van-button {
      margin-left: 8px;
    }
  }
  > .setting {
    padding-bottom: 80px;
  }

  :deep(.van-tabs__nav--card) {
    margin: 0;
  }

  > .control {
    display: flex;
    align-items: stretch;
    > .van-search {
      flex: 1;
    }
  }

  > .footer {
    position: fixed;
    display: flex;
    align-items: center;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    padding: 0 40px;
  }
}

.dialog-field {
  background-color: @gray-1;
}

// .aitype-box {
.summary {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  .date-time {
    display: flex;
    justify-content: space-between;
    .time {
    }
    .detail {
      color: rgb(25, 137, 250);
    }
  }
  .summary-txt {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0;
  }
  .total-keyword {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
// }

.speech-list {
  .speech-item {
    padding: 5px;
    // margin: 15px 0;
    box-shadow: -1px 4px 5px #eee;
    .speech-content {
      padding: 5px;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      button {
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

.theme-detail {
  font-size: inherit;
}
.no-data {
  color: #aaa;
  text-align: center;
  margin-top: 20px;
}

.aitypes-items {
  // width: calc(100% - 32px);
  :deep(.van-cell__title) {
    width: calc(100% - 32px);
  }
  :deep(.van-collapse-item__title) {
    display: flex;
    align-items: center;
  }
}
</style>
